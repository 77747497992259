import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import { NewButton } from 'components/common/Button';
import {
  Box,
  Wrapper,
  BoxTimer,
  RightBox,
  RejectButtonBox,
  BoxCheck,
  BoxHeader,
  StatusSigned,
  NoAccessConnection,
  ButtonContainer,
  BlockSignedContract,
  TitleSignedContract,
  BoxSignedContract,
  HeaderSignedContract,
  SignedContractStatusName,
  SignedStatusBox,
  SignedStatus,
  WarningSignedAvr,
} from './DocumentContract.style';
import { Checkbox } from 'components/common/Checkbox';
import { sign } from '../TestStand/signtest/ncalayer';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { LoadingGif } from 'components/common/LoadingGif';
import IconDocuments from 'assets/img/IconDocuments.svg';
import { ErrorModal } from 'components/common/ErrorModal';
import { avrSign } from 'components/TestStand/signtest/avrSign';

interface DocumentContractProps {
  subActive: 'main' | 'commission' | 'avrSub'; // текущая выбранная вкладка

  fileUidMain: string | null; // UID PDF-файла для Договора
  fileUidCommission?: string | null; // UID PDF-файла для Договора комиссии
  fileUidAvr?: string | null; // UID PDF-файла для АВР

  fileHashMain: string; // Хеш для Договора
  fileHashCommission?: string; // Хеш для Договора комиссии
  fileHashAvr?: string; // Хеш для АВР

  signingDtEnd: string;
  investorName: string;
  investorSigned: boolean;
  businessSigned: boolean;
  businessName: string;
  status: string;
  idContract: string | number;
  onFetchData: () => void;
  contractNum: string;
  isAvrFileSigned?: boolean;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const BASE_URL_PUBLIC = `${process.env.REACT_APP_BASE_URL}/public/api/v1/`;

export const DocumentContract: React.FC<DocumentContractProps> = ({
  subActive,
  fileUidMain,
  fileUidCommission,
  fileUidAvr,
  fileHashMain,
  fileHashCommission,
  fileHashAvr,
  signingDtEnd,
  investorName,
  investorSigned,
  businessSigned,
  businessName,
  status,
  idContract,
  onFetchData,
  contractNum,
  isAvrFileSigned,
}) => {
  const { userDataLight } = useAppContext();
  const { id } = useParams();

  // ---------------------------------------------------
  // Состояния для PDF
  // ---------------------------------------------------
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  // Храним blob-URL (или base64) для АВР
  const [AvrPdfUrl, setAvrPdfUrl] = useState<string | null>(null);

  // ---------------------------------------------------
  // Прочие состояния
  // ---------------------------------------------------
  const [isAgreed, setIsAgreed] = useState(false);
  const [isAgreedAvr, setIsAgreedAvr] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [signingLoading, setSigningLoading] = useState(false);
  const [error, setError] = useState<{ header: string; text: string } | null>(null);
  const [isAvrSigning, setIsAvrSigning] = useState(false); // Лоадер для процесса подписания АВР

  // ---------------------------------------------------
  // Роли
  // ---------------------------------------------------
  const isUserRole =
    userDataLight?.roles.includes('ADMIN') || userDataLight?.roles.includes('MANAGER');
  const isInvestor = userDataLight?.organizationRole === 'INVESTOR';
  const isBusiness = userDataLight?.organizationRole === 'BUSINESS';

  const isInvestorAndSigned = isInvestor && investorSigned;
  const isBusinessAndSigned = isBusiness && businessSigned;
  const isSigned = investorSigned && businessSigned && status === 'SIGNED';

  const isInvestorTitle = isInvestor ? `Договор с бизнесом` : `Договор с инвестором`;
  const isAvrSigned = isAvrFileSigned === true;

  // ---------------------------------------------------
  // Определяем, какой файл (UID/Hash) для отображения в iframe
  // ---------------------------------------------------
  let currentFileUid = fileUidMain;
  let currentFileHash = fileHashMain;
  if (subActive === 'commission') {
    currentFileUid = fileUidCommission || null;
    currentFileHash = fileHashCommission || '';
  } else if (subActive === 'avrSub') {
    currentFileUid = fileUidAvr || null;
    currentFileHash = fileHashAvr || '';
  }

  // ---------------------------------------------------
  // 1) Загрузка PDF для iframe (зависит от subActive)
  // ---------------------------------------------------
  useEffect(() => {
    if (!currentFileUid) {
      setDocumentUrl('');
      return;
    }
    setPdfLoading(true);

    axios
      .get(`${BASE_URL}ui/file/${currentFileUid}`, {
        responseType: 'blob',
        withCredentials: true,
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        setDocumentUrl(fileUrl);
      })
      .catch((error) => {
        console.error('Ошибка при получении документа (iframe):', error);
      })
      .finally(() => {
        setPdfLoading(false);
      });
  }, [currentFileUid]);

  // ---------------------------------------------------
  // 2) Загрузка АВР всегда (при наличии fileUidAvr)
  // ---------------------------------------------------
  useEffect(() => {
    if (fileUidAvr) {
      axios
        .get(`${BASE_URL}ui/file/${fileUidAvr}`, {
          responseType: 'blob',
          withCredentials: true,
        })
        .then((response) => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          setAvrPdfUrl(fileUrl);
        })
        .catch((error) => {
          console.error('Ошибка при загрузке PDF для АВР:', error);
        });
    } else {
      setAvrPdfUrl(null);
    }
  }, [fileUidAvr]);

  // ---------------------------------------------------
  // Подписание Договора/Комиссии (через ncalayer)
  // ---------------------------------------------------
  const handleSignClick = async () => {
    if (!currentFileHash) return;
    setSigningLoading(true);

    const endpoint = isInvestor
      ? `${BASE_URL}cabinet/investor/contract/sign`
      : `${BASE_URL}cabinet/creditor/contract/sign`;

    try {
      const result = await sign(currentFileHash, endpoint, parseInt(id as string));
      if (result) {
        onFetchData();
      }
    } catch (error) {
      console.error(error);
      setError({
        header: 'Ошибка подписания',
        text: 'Произошла ошибка при попытке подписать документ. Попробуйте снова.',
      });
    } finally {
      setSigningLoading(false);
    }
  };

  // ---------------------------------------------------
  // Подписание АВР (avrSign)
  // ---------------------------------------------------
  const handleSignOfAvr = async () => {
    if (!AvrPdfUrl) return; // Если вдруг по какой-то причине не загрузилось

    setIsAvrSigning(true);
    try {
      const endpoint = `${BASE_URL}cabinet/creditor/contract/avr/sign`;
      const response = await avrSign(AvrPdfUrl, endpoint, String(idContract));

      if (response.status === 200) {
        onFetchData();
      }
    } catch (error) {
      console.error('Ошибка при подписании АВР:', error);
      setError({
        header: 'Ошибка подписания АВР',
        text: 'Произошла ошибка при попытке подписать Акт выполненных работ.',
      });
    } finally {
      setIsAvrSigning(false);
    }
  };

  // ---------------------------------------------------
  // Отклонение
  // ---------------------------------------------------
  const handleRejectClick = async () => {
    const rejectEndpoint = isInvestor
      ? `${BASE_URL}cabinet/investor/contract/reject/${id}`
      : `${BASE_URL}cabinet/creditor/contract/reject/${id}`;

    try {
      await axios.get(rejectEndpoint, { withCredentials: true });
      onFetchData();
    } catch (error) {
      setError({
        header: 'Ошибка отклонения',
        text: 'Произошла ошибка при попытке отклонить документ. Попробуйте снова.',
      });
    }
  };

  // ---------------------------------------------------
  // Прочие функции
  // ---------------------------------------------------
  const handleCheckboxChange = () => {
    setIsAgreed((prev) => !prev);
  };

  const handleCheckboxChangeAvr = () => {
    setIsAgreedAvr((prev) => !prev);
  };

  const closeErrorModal = () => {
    setError(null);
  };

  // ---------------------------------------------------
  // Вычисление оставшегося времени
  // ---------------------------------------------------
  const calculateRemainingTime = (endDateString: string) => {
    const endDate = new Date(endDateString);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) return 'Время истекло';

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`;
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  };

  const remainingTime = calculateRemainingTime(signingDtEnd);
  const isTimeExpired = remainingTime === 'Время истекло';
  const isLessThanADay = remainingTime.includes('истекло');

  // Проверка, отклонён ли договор
  const isContractActive = () => {
    return status !== 'REJECT_CREDITOR' && status !== 'REJECT_BUSINESS';
  };

  // ---------------------------------------------------
  // Генерация итоговых PDF (Подписанный договор / Квитанция)
  // ---------------------------------------------------
  const downloadPdf = async (apiUrl: string, fileName: string) => {
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Ошибка при скачивании PDF:', error);
    }
  };

  const generateQR = async () => {
    if (!isSigned) return;
    const link = 'https://moneylink.kz/';
    const apiUrl = isInvestor
      ? `${BASE_URL}cabinet/investor/contract/generate/qr?contractNumber=${contractNum}&link=${link}`
      : `${BASE_URL}cabinet/creditor/contract/generate/qr?contractNumber=${contractNum}&link=${link}`;
    await downloadPdf(apiUrl, 'Подписанный договор.pdf');
  };

  const generateReceipt = async () => {
    if (!isSigned) return;
    setLoadingReceipt(true);
    const apiUrl = `${BASE_URL_PUBLIC}ui/doc/receipt/generate?contractNumber=${contractNum}`;
    try {
      await downloadPdf(apiUrl, 'Квитанция.pdf');
    } catch (error) {
      console.error('Ошибка при скачивании квитанции:', error);
    } finally {
      setLoadingReceipt(false);
    }
  };

  // ---------------------------------------------------
  // Вспомогательные рендеры
  // ---------------------------------------------------
  const getTitle = () => {
    if (subActive === 'main') return 'Посмотреть договор';
    if (subActive === 'commission') return 'Посмотреть договор комиссии';
    if (subActive === 'avrSub') return 'Посмотреть АВР';
    return 'Документ';
  };

  // Рендер статусов и кнопок (справа)
  const renderContractStatus = () => {
    if (status === 'REJECT_CREDITOR') {
      return <StatusSigned>Договор отклонен заемщиком</StatusSigned>;
    }
    if (status === 'REJECT_BUSINESS') {
      return <StatusSigned>Договор отклонен инвестором</StatusSigned>;
    }

    // Если договор не отклонён, рисуем блок
    return (
      <BlockSignedContract>
        <TitleSignedContract>Документы</TitleSignedContract>

        {isBusiness && (
          <>
            {/* Договор комиссии */}
            <BoxSignedContract>
              <HeaderSignedContract>
                <img src={IconDocuments} alt='iconDoc' />
                Договор комиссии
              </HeaderSignedContract>
              <SignedStatusBox>
                <SignedContractStatusName>TOO “Fundsmarket”</SignedContractStatusName>
                <SignedStatus>Подписан</SignedStatus>
              </SignedStatusBox>
              <SignedStatusBox>
                <SignedContractStatusName>{businessName}</SignedContractStatusName>
                <SignedStatus>Подписан</SignedStatus>
              </SignedStatusBox>
            </BoxSignedContract>

            {/* Акт выполненных работ по договору о комиссии */}
            <BoxSignedContract>
              <HeaderSignedContract>
                <img src={IconDocuments} alt='iconDoc' />
                Акт выполненных работ по договору о комиссии
              </HeaderSignedContract>
              <SignedStatusBox>
                <SignedContractStatusName>TOO “Fundsmarket”</SignedContractStatusName>
                <SignedStatus>Подписан</SignedStatus>
              </SignedStatusBox>
              <SignedStatusBox>
                <SignedContractStatusName>{businessName}</SignedContractStatusName>
                <SignedStatus>
                  {!isAvrSigned ? (
                    <NewButton
                      onClick={handleSignOfAvr}
                      disabled={!isAgreedAvr || isAvrSigning}
                      loading={isAvrSigning}
                      className='mini'
                      text='подписать'
                    />
                  ) : (
                    'Подписан'
                  )}
                </SignedStatus>
              </SignedStatusBox>
              {!isAvrSigned && (
                <BoxCheck>
                  <Checkbox checked={isAgreedAvr} onChange={handleCheckboxChangeAvr} />
                  <p>С документом ознакомлен(-а)</p>
                </BoxCheck>
              )}
            </BoxSignedContract>
          </>
        )}

        {/* Основной договор (инвестор - бизнес) */}
        <BoxSignedContract>
          <HeaderSignedContract>
            <img src={IconDocuments} alt='iconDoc' />
            {isInvestorTitle}
          </HeaderSignedContract>
          <SignedStatusBox>
            <SignedContractStatusName>{investorName}</SignedContractStatusName>
            <SignedStatus>
              {isUserRole && isInvestor && !isTimeExpired && !investorSigned ? (
                <NewButton
                  onClick={handleSignClick}
                  disabled={!isAgreed || signingLoading}
                  loading={signingLoading}
                  className='mini'
                  text='подписать'
                />
              ) : investorSigned ? (
                'Подписан'
              ) : !isTimeExpired ? (
                'Изучает договор'
              ) : (
                <span style={{ color: 'red' }}>Время истекло</span>
              )}
            </SignedStatus>
          </SignedStatusBox>

          <SignedStatusBox>
            <SignedContractStatusName>{businessName}</SignedContractStatusName>
            <SignedStatus>
              {isUserRole && isBusiness && !isTimeExpired && !businessSigned ? (
                <NewButton
                  onClick={handleSignClick}
                  disabled={!isAgreed || signingLoading || !isAvrSigned}
                  loading={signingLoading}
                  className='mini'
                  text='подписать'
                />
              ) : businessSigned ? (
                'Подписан'
              ) : !isTimeExpired ? (
                'Изучает договор'
              ) : (
                <span style={{ color: 'red' }}>Время истекло</span>
              )}
            </SignedStatus>
          </SignedStatusBox>

          {/* Если АВР не подписан, бизнес не может подписать основной договор */}
          {!isAvrSigned && isBusiness && !businessSigned && (
            <WarningSignedAvr>
              Необходимо предварительно подписать «Акт выполненных работ по договору о комиссии»
            </WarningSignedAvr>
          )}

          {/* Чекбокс "С документом ознакомлен" перед подписанием */}
          {isAvrSigned &&
            !isInvestorAndSigned &&
            !isBusinessAndSigned &&
            isUserRole &&
            !isTimeExpired && (
              <BoxCheck>
                <Checkbox checked={isAgreed} onChange={handleCheckboxChange} />
                <p>С документом ознакомлен(-а)</p>
              </BoxCheck>
            )}
        </BoxSignedContract>

        {/* Отклонение (только если ещё не подписали) */}
        {isUserRole && !isInvestorAndSigned && !isBusinessAndSigned && !isTimeExpired && (
          <RejectButtonBox>
            <NewButton text='Отказаться' className='red' onClick={handleRejectClick} />
          </RejectButtonBox>
        )}

        {!isUserRole && (
          <NoAccessConnection>Подписать может только руководитель компании</NoAccessConnection>
        )}
      </BlockSignedContract>
    );
  };

  // ---------------------------------------------------
  // Рендер
  // ---------------------------------------------------
  return (
    <Box>
      {isContractActive() && (
        <Wrapper>
          <BoxHeader>
            <h3>{getTitle()}</h3>
            {/* Кнопки "Подписанный договор" / "Квитанция" только при полном подписании */}
            {subActive === 'main' && (
              <ButtonContainer>
                {isSigned && <NewButton onClick={generateQR} text='Подписанный договор' />}
                {isSigned && (
                  <NewButton onClick={generateReceipt} text='Квитанция' loading={loadingReceipt} />
                )}
              </ButtonContainer>
            )}

            {/* Таймер */}
            <BoxTimer>
              <img
                src={isLessThanADay ? IconRedTimer : IconBlackTimer}
                alt='Timer Icon'
                style={{ marginRight: '5px' }}
              />
              <span style={{ color: isLessThanADay ? 'red' : 'inherit' }}>{remainingTime}</span>
            </BoxTimer>
          </BoxHeader>

          {/* Отображение PDF (main/commission/avr) */}
          {pdfLoading ? (
            <div style={{ marginTop: 20 }}>
              <LoadingGif />
            </div>
          ) : documentUrl ? (
            <iframe
              src={documentUrl}
              allow='fullscreen'
              width='100%'
              height='630px'
              style={{ border: 'none' }}
            />
          ) : (
            <div style={{ marginTop: 10, color: 'red' }}>Нет файла для вкладки «{subActive}»</div>
          )}
        </Wrapper>
      )}

      {/* Правый блок со статусами */}
      <RightBox>{renderContractStatus()}</RightBox>

      {/* Модалка с ошибками */}
      {error && (
        <ErrorModal
          headerError={error.header}
          textError={error.text}
          buttonClose='Закрыть'
          onClick={closeErrorModal}
        />
      )}
    </Box>
  );
};

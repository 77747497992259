export const getReviewWord = (count: number): string => {
  const remainder100 = count % 100;
  const remainder10 = count % 10;

  if (remainder100 >= 11 && remainder100 <= 14) {
    return 'отзывов';
  } else if (remainder10 === 1) {
    return 'отзыв';
  } else if (remainder10 >= 2 && remainder10 <= 4) {
    return 'отзыва';
  } else {
    return 'отзывов';
  }
};

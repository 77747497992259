import React, { useState } from 'react';
import styled from 'styled-components';
import { default as PromptWindowIcon } from 'assets/img/PromptWindowIcon.svg';

interface TooltipProps {
  show: boolean;
}

const Tooltip = styled.div<TooltipProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: absolute;
  /* Размещаем «облачко» выше иконки */
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  padding: 4px;
  background-color: #f4f9ff; /* можно заменить на белый или любой другой */
  border-radius: 8px;

  /* Стрелочка снизу тултипа */
  &::after {
    content: '';
    position: absolute;
    top: 100%; /* указывает, что стрелка будет внизу тултипа */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    /* Верх стрелочки — цвет тултипа, остальные стороны прозрачные */
    border-color: #f4f9ff transparent transparent transparent;
  }

  p {
    color: var(--primary-400, #0f62fe);
    text-align: center;
    font-family: 'Jost';
    font-size: 10px;
    font-weight: 400;
    line-height: 15px; /* 150% */
  }
`;

const IconContainer = styled.div`
  position: relative; /* Обязательно, чтобы Tooltip был позиционирован относительно иконки */
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

interface PromptWindowProps {
  description: string;
}

const PromptWindow: React.FC<PromptWindowProps> = ({ description }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <IconContainer
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}>
      <img src={PromptWindowIcon} alt='prompt' />
      <Tooltip show={showTooltip}>
        <p>{description}</p>
      </Tooltip>
    </IconContainer>
  );
};

export default PromptWindow;

import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 62%;
  overflow-y: auto;
  border-radius: 4px;
  background: var(--neutral-50, #fefefe);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  h3 {
    color: #0d0d0d;
    font-size: 28px;
    font-weight: 600;
    line-height: 125%;
  }
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  h3 {
    color: #0d0d0d;
    font-size: 22px;
    font-weight: 600;
    line-height: 106%; /* 25.44px */
  }
`;

const BoxTimer = styled.div`
  border-radius: 50px;
  background: var(--White, #fefefe);
  padding: 12px 12px;
  align-items: center;
  display: flex;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
export const RejectButtonBox = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
  }
`;

const BoxCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  p {
    color: var(--neutral-600, #686868);
    font-family: 'Jost';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
`;

const StatusSigned = styled.h3`
  color: var(--Control, #fb4753);
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
`;

const NoAccessConnection = styled.p`
  color: var(--Control, #9aa4b0);
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 400;
  line-height: 125%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const BlockSignedContract = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutral-50, #fefefe);
`;

export const TitleSignedContract = styled.p`
  color: #333;
  font-family: 'Jost';
  font-size: 20px;
  font-weight: 600;
  line-height: 125%; /* 25px */
`;

export const BoxSignedContract = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #f4f4f4;
`;

export const HeaderSignedContract = styled.div`
  display: flex;
  gap: 8px;
  color: var(--Text-text-primary, #161616);
  align-items: center;
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  img {
    width: 16px;
    height: 16px;
  }
`;
export const SignedStatusBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SignedContractStatusName = styled.p`
  color: var(--neutral-600, #686868);
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

export const SignedStatus = styled.span`
  color: var(--Link-link-primary, #0f62fe);
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

export const WarningSignedAvr = styled.p`
  color: var(--neutral-600, #686868);
  text-align: right;
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  margin-left: auto;
`;

export {
  Box,
  Wrapper,
  BoxTimer,
  RightBox,
  BoxCheck,
  BoxHeader,
  StatusSigned,
  NoAccessConnection,
  ButtonContainer,
};

import styled from 'styled-components';

const CustomSelectWrapper = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  color: #292929;
  font-size: 28px;
  font-weight: 600;
  line-height: 106%;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-bottom: 24px;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
  /* Настраиваем общий вид календаря */
  .react-calendar {
    font-family: 'Jost', sans-serif;
    font-size: 14px; /* общий размер шрифта */
  }

  .react-calendar__navigation button {
    font-size: 16px;
    font-weight: bold;
  }

  /* Подсветка дат, где есть платежи */
  .highlight-date {
    background: linear-gradient(0deg, #fa4d56 0%, #fa4d56 100%), #f4f4f4;

    color: #fff;
  }

  .react-calendar__tile--active {
    background: linear-gradient(0deg, #0f62fe 0%, #0f62fe 100%), #f4f4f4;
    color: #fff;
  }
`;

export const TableBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarBox = styled.div`
  width: 100%;
  max-width: 300px;
`;

const CustomSelect = styled.div`
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  user-select: none;
  z-index: 1;
  max-width: 120px;
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.paid {
    border-bottom: 1px solid #4caf50; /* Green */
  }

  &.unpaid {
    border-bottom: 1px solid #f44336; /* Red */
  }

  &.awaiting {
    border-bottom: 1px solid #ff9800; /* Orange */
  }
`;

const CustomOptions = styled.div`
  background: var(--neutral-50, #fefefe);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow-y: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  max-width: 120px;
`;

const CustomOption = styled.div`
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  max-width: 120px;
  width: 100%;

  &:hover {
    background: #f4f4f4;
  }

  &.paid {
    border-bottom: 1px solid #4caf50; /* Green */
  }

  &.unpaid {
    border-bottom: 1px solid #f44336; /* Red */
  }

  &.awaiting {
    border-bottom: 1px solid #ff9800; /* Orange */
  }
`;

const ArrowIcon = styled.div<{ expanded: boolean }>`
  display: inline-block;
  transform: rotate(${(props) => (props.expanded ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const ButtonBoxDowland = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 14px;
  button {
    width: 250px;
  }
`;

export {
  CustomSelectWrapper,
  CustomSelect,
  CustomOptions,
  CustomOption,
  ArrowIcon,
  ButtonBoxDowland,
};

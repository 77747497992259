import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import IconStar from 'assets/img/IconStar.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import {
  Container,
  MainInfoBox,
  CardScoring,
  CardDescription,
  Circle,
  ChartContainer,
  CenterText,
  MainInfoItem,
  LegendCard,
  LegendCardBox,
  MainInfoItemDate,
} from './InfoBusinessNew.style';
import { InfoBusinessProps } from './InfoBusiness.interface';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoContent,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { LoadingGif } from 'components/common/LoadingGif';

Chart.register(ArcElement, Tooltip, Legend);

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

// DonutChart Component
export const DonutChart: React.FC<{
  funded: number;
  notFunded: number;
  totalRequests: number;
  title: string;
}> = ({ funded, notFunded, totalRequests, title }) => {
  const data = {
    labels: ['Профинансировано', 'Не профинансировано'],
    datasets: [
      {
        data: [funded, notFunded],
        backgroundColor: ['#F8B801', '#01AEB7'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Это уберет легенду сверху
      },
      tooltip: {
        enabled: false, // Отключить всплывающие подсказки
      },
    },
    cutout: '75%', // строка с процентом для определения толщины кольца
    maintainAspectRatio: false, // Добавлено, чтобы предотвратить искажение аспектного соотношения при изменении размеров контейнера
  };

  return (
    <ChartContainer>
      <Doughnut data={data} options={options} />
      <CenterText>
        <h4>{totalRequests}</h4>
        <p>{title}</p>
      </CenterText>
    </ChartContainer>
  );
};

// InfoBusinessNew Component
export const InfoBusinessNew: React.FC<InfoBusinessProps> = ({ id }) => {
  const [businessStats, setBusinessStats] = useState<
    { name: string; count: number; sum: string | null }[] | null
  >(null);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoExpandedStat, setIsMainInfoExpandedStat] = useState(true);
  const [dateRegistr, setDateRegistr] = useState('');

  useEffect(() => {
    const fetchBusinessStats = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/report/history/${id}`,
          { withCredentials: true },
        );
        setBusinessStats(response.data.report); // Извлекаем только report
        setDateRegistr(response.data.regDate); // Сохраняем дату регистрации
      } catch (error) {
        console.error('Error fetching business stats:', error);
      }
    };

    fetchBusinessStats();
  }, [id]);

  if (!businessStats) {
    return <LoadingGif />;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  };

  const getStat = (name: string) => {
    const stat = businessStats?.find((item) => item.name === name);
    return stat || { count: 0, sum: '0' };
  };

  const fundedRequests = getStat('request_fund').count;
  const totalRequests = getStat('requests').count;
  const notFundedRequests = totalRequests - fundedRequests;

  return (
    <Container>
      {/* Аналитика бизнеса */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconStar} alt='icon' />
          Аналитика бизнеса
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              <MainInfoItemDate>
                <span>Дата регистрации в системе:</span>
                {dateRegistr ? formatDate(dateRegistr) : 'Неизвестно'}
              </MainInfoItemDate>

              <MainInfoItem>
                <div>
                  <span>Всего заявок подано:</span>
                  {getStat('requests').count}
                </div>
                <div>
                  <span>На сумму:</span>
                  {parseFloat(getStat('requests').sum || '0').toLocaleString('ru-RU')} ₸
                </div>
              </MainInfoItem>
              <MainInfoItem>
                <div>
                  <span>Заявок профинансировано:</span>
                  {getStat('requests_fund').count}
                </div>
                <div>
                  <span>На сумму:</span>
                  {parseFloat(getStat('requests_fund').sum || '0').toLocaleString('ru-RU')} ₸
                </div>
              </MainInfoItem>
              <MainInfoItem>
                <div>
                  <span>Заявок активно:</span>
                  {getStat('requests_active').count}
                </div>
                <div>
                  <span>На сумму:</span>
                  {parseFloat(getStat('requests_active').sum || '0').toLocaleString('ru-RU')} ₸
                </div>
              </MainInfoItem>
              <MainInfoItem>
                <div>
                  <span>Заявок на рассмотрении:</span>
                  {getStat('request_inprocess').count}
                </div>
                <div>
                  <span>На сумму:</span>
                  {parseFloat(getStat('request_inprocess').sum || '0').toLocaleString('ru-RU')} ₸
                </div>
              </MainInfoItem>
              <MainInfoItem>
                <div>
                  <span>Всего договоров:</span>
                  {getStat('contracts').count}
                </div>
                <div>
                  <span>На сумму:</span>
                  {parseFloat(getStat('contracts').sum || '0').toLocaleString('ru-RU')} ₸
                </div>
              </MainInfoItem>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>

      {/* Статистика */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpandedStat(!isMainInfoExpandedStat)}>
          <img src={IconStar} alt='icon' />
          Статистика
          <ArrowIcon src={isMainInfoExpandedStat ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpandedStat && (
          <MainInfoBox>
            <CardScoring>
              <h3>Статистика по заявкам</h3>
              <DonutChart
                funded={fundedRequests}
                notFunded={notFundedRequests}
                totalRequests={totalRequests}
                title='Всего заявок'
              />
              <CardDescription>
                <LegendCard>
                  <Circle color='#F8B801' />
                  <LegendCardBox>
                    <p>Профинансировано</p>
                    {fundedRequests}
                  </LegendCardBox>
                </LegendCard>
                <LegendCard>
                  <Circle color='#01AEB7' />
                  <LegendCardBox>
                    <p>Не профинансировано</p>

                    {notFundedRequests}
                  </LegendCardBox>
                </LegendCard>
              </CardDescription>
            </CardScoring>
          </MainInfoBox>
        )}
      </ExpandableSection>
    </Container>
  );
};

import styled, { css } from 'styled-components';

export const DraftBox = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--functional-negative, #fa4d56);
  background: var(--neutral-50, #fefefe);

  h4 {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 125%;
  }
  p {
    color: var(--neutral-600, var(--neutral-600, #686868));
    font-family: 'IBM Plex Sans';
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }
`;

export const DraftButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: -6px;
  label {
    color: var(--neutral-600, #686868);
    font-size: 12px;
    font-weight: 600;
    line-height: 125%;
  }
`;

export const DropdownHeader = styled.div<{ isOpen: boolean; $hasContent: boolean }>`
  margin-top: 4px;
  padding: 8px;
  border: 1px solid var(--Control, #f4f4f4);
  background: var(--neutral-100, #f4f4f4);

  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-400, #a0a0a0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}
  &:focus {
    outline: none;
    border: 1px solid var(--Border-border-interactive, #0f62fe);
    background: var(--neutral-100, #f4f4f4);
    border-radius: 4px;
    color: var(--neutral-800, #303030);
  }
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid var(--Control, #9aa4b0);
  border-radius: 6px;
  background: var(--White, #fff);
  z-index: 1000;
  margin-top: 4px;
  overflow-x: scroll;
  max-height: 110px;
`;

export const DropdownListItem = styled.div`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-300, #bcbcbc);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &:hover {
    background: var(--Light, #f5f5f5);
  }
`;

export const DropdownArrow = styled.svg<{ isOpen: boolean }>`
  width: 10px;
  height: 10px;
  margin-left: 8px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const ErrorTitle = styled.p`
  color: #fa4d56;
  font-size: 12px;
`;

export const AcceptNewOffersNo = styled.p`
  color: #fa4d56;
  font-size: 16px;
`;

export const MainDecorContent = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  div {
    display: flex;
    justify-content: space-between;
  }
`;

export const DesignBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainDecorLogo = styled.img`
  max-width: 156px;
  height: 156px;
  border-radius: 8px;
  width: 100%;
`;

export const MainDecorInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--Hover-Gray-60, #5e5e5e);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  p {
    color: var(--neutral-800, #303030);
  }
`;

export const InfoDescription = styled.span`
  max-width: 500px;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Number of lines to show */
  -webkit-box-orient: vertical;
`;

export const MainDecorTitle = styled.p`
  color: var(--neutral-800, var(--neutral-800, #303030));
  font-family: 'IBM Plex Sans';
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const DocLinkBox = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--neutral-800, #303030);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  a {
    color: blue; /* Traditional link color */
    text-decoration: underline; /* Adds the underline */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
  }
`;

export const EditInputBox = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0; /* top: 0; left: 0; right: 0; bottom: 0; */
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999; // чтобы было поверх всего
`;

export const ModalContent = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 60vw;
  height: 90vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const ButtonBoxSigned = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  padding: 24px;
  button {
    width: 100%;
  }
`;

export const HeaderPdf = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  h3 {
    color: #000;

    text-align: center;
    font-family: 'Jost';
    font-size: 28px;
    font-weight: 600;
    line-height: 40px; /* 142.507% */
  }
  p {
    color: #242527;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;

export const FeedbackDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  h4 {
    color: var(--neutral-400, #a0a0a0);
    font-family: 'Jost';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  p {
    color: var(--neutral-900, #141414);
    font-family: 'Jost';
    font-size: 20px;
    font-weight: 600;
    line-height: 125%; /* 25px */
  }
`;

import axios from 'axios';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import HeaderPdfLogo from 'assets/img/HeaderPdf.png';
import {
  Container,
  BreadCrumbsBox,
  HeaderBox,
  TagRisk,
  SwitcherBox,
  Tab,
  ContainerContent,
  LeftBlock,
  RightBlock,
  InfoBoxRight,
  InfoBoxRightCard,
  ButtonBox,
  Timer,
  TagTime,
  MainInfoBox,
  MainInfoItem,
  MainInfoTitle,
  MainInfoContent,
  ContainerMain,
  ExpandableSection,
  ArrowIcon,
  MainInfoContentEdit,
  MainInfoItemText,
  ProgressBarContainer,
  ProgressBarFiller,
  StarWrapper,
  StarsRow,
  RatingItemInvest,
  RatingLabelInvest,
  RatingsRow,
  FeedbackBox,
  FeedbackBoxStar,
  FeedbackBoxHeader,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import {
  DraftBox,
  DraftButtonBox,
  DropdownContainer,
  DropdownHeader,
  DropdownListContainer,
  DropdownListItem,
  ErrorTitle,
  MainDecorContent,
  MainDecorLogo,
  MainDecorInfoBox,
  MainDecorTitle,
  AcceptNewOffersNo,
  InfoDescription,
  DocLinkBox,
  EditInputBox,
  ModalOverlay,
  ModalContent,
  ButtonBoxSigned,
  HeaderPdf,
  DesignBox,
  FeedbackDescription,
} from './RequestDetailsNew.style';
import { NewButton } from 'components/common/Button';
import IconRedTimer from 'assets/img/IconRedTimer.svg';
import CircleWhite from 'assets/img/CircleWhite.svg';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import IconHelpHouse from 'assets/img/IconHelpHouse.svg';
import IconDecor from 'assets/img/IconDecor.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { SentOffersList } from 'components/ApplicationInvestor';
import { OffersBusinessList } from 'components/OffersBusinessList';
import { ApplicationDocumentsList } from './ApplicationDocumentsList';
import { Account } from 'components/NewCreateApplication/NewCreateApplication.interface';
import { File } from './ApplicationDocumentsList/ApplicationDocumentsList.interface';
import { ErrorModal } from 'components/common/ErrorModal';
import { TextArea } from 'components/common/TextArea/TextArea';
import { LoadingGif } from 'components/common/LoadingGif';
import { WarningModal } from 'components/common/WarningModal';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { commissionSign } from 'components/TestStand/signtest/commissionSign';
import StarIconMini from 'assets/img/StarIconMini.svg';
import { formatDateTime } from 'utils/formatDate';
import { RequestData } from 'components/ApplicationInvestor/ApplicationInvestor.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

const formatNumberWithSpaces = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const RequestDetailsNew = () => {
  const [requestData, setRequestData] = useState<RequestData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('mainInfo');
  const [remainingTime, setRemainingTime] = useState('00:00:00');
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoFinancing, setIsMainInfoFinancing] = useState(true);
  const [isMainInfoDecor, setIsMainInfoDecor] = useState(true);
  const [showOffers, setShowOffers] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<RequestData | null>(null);
  const [originalFiles, setOriginalFiles] = useState<File[]>([]);
  const [minAmountError, setMinAmountError] = useState('');
  const [amountError, setAmountError] = useState('');
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);
  const [contracts, setContracts] = useState<any[]>([]);
  const [selectedContract, setSelectedContract] = useState<any | null>(null);
  const [isContractDropdownOpen, setIsContractDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
  const [isGoalDropdownOpen, setIsGoalDropdownOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // State to manage saving process
  const [isPublishing, setIsPublishing] = useState(false); // State to manage publish button disabled state
  const [showErrorModal, setShowErrorModal] = useState(false); // State to manage the error modal visibility
  const [logoSrc, setLogoSrc] = useState<string | null>(null); // State to store the logo image source
  const [industrys, setIndustry] = useState<any[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any | null>(null);
  const [goals, setGoal] = useState<any[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<any | null>(null);
  const [showWarningModal, setShowWarningModal] = useState(false);

  // === Новые состояния для контроля PDF и подписания договора комиссии ===
  const [isCommissionPdfLoading, setIsCommissionPdfLoading] = useState(false); // Лоадер для PDF
  const [isCommissionSigning, setIsCommissionSigning] = useState(false); // Лоадер для процесса подписания
  const [commissionPdfUrl, setCommissionPdfUrl] = useState<string | null>(null);
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  // Новые стейты для вкладки "Обратная связь"
  const [feedbackList, setFeedbackList] = useState<any[]>([]);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  // Массив булевых значений, чтобы независимо раскрывать каждый отзыв
  const [expandedFeedbackIndices, setExpandedFeedbackIndices] = useState<boolean[]>([]);

  // Если заявка активна, то нужно показывать вкладку "Обратная связь".
  // Можно ориентироваться на requestData.status, например.
  const isActiveRequest = requestData?.status !== 'DRAFT'; // или как у вас условие

  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const navigate = useNavigate();

  // Запрос на получение фидбэка
  const fetchFeedback = useCallback(async () => {
    if (!id) return;
    setIsFeedbackLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/cabinet/creditor/request/get/${id}/feedback`, {
        withCredentials: true,
      });
      const data = response.data || [];
      setFeedbackList(data);

      // Изначально все отзывы "свернуты"
      setExpandedFeedbackIndices(Array(data.length).fill(false));
    } catch (err) {
      console.error('Ошибка при загрузке обратной связи:', err);
      setError('Ошибка при загрузке обратной связи');
    } finally {
      setIsFeedbackLoading(false);
    }
  }, [id]);

  // При переключении на вкладку feedback — подгружаем данные (или можно грузить при загрузке всего компонента)
  useEffect(() => {
    if (activeTab === 'feedback' && isActiveRequest) {
      fetchFeedback();
    }
  }, [activeTab, isActiveRequest, fetchFeedback]);

  const fetchRequestDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/cabinet/creditor/request/get?id=${id}`, {
        withCredentials: true,
      });

      setRequestData(response.data);
      setEditedData(response.data);
      setOriginalFiles(response.data.files);
      setSelectedAccount(response.data?.accountInfo || null);
      setSelectedContract(response.data?.contracts?.[0] || null);
      setSelectedIndustry(response.data.industry || null);
      setSelectedGoal(response.data.funding_goals || null);

      // Fetch the logo if logoFileId is present
      if (response.data.logoFileId) {
        const logoBlob = await fetchLogo(response.data.logoFileId);
        setLogoSrc(URL.createObjectURL(logoBlob));
      }
    } catch (err) {
      setError('Ошибка загрузки данных: ' + (err instanceof Error ? err.message : String(err)));
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!tab) {
      setActiveTab('mainInfo');
    }
  }, [tab]);

  const fetchLogo = async (logoFileId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/ui/file/${logoFileId}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching logo:', error);
      setError('Error fetching logo');
      throw error;
    }
  };

  const handleShowCommissionDoc = async () => {
    if (!requestData?.commissionContractDoc) return;

    try {
      // Начинаем загрузку PDF
      setIsCommissionPdfLoading(true);
      const response = await axios.get(`${BASE_URL}/ui/file/${requestData.commissionContractDoc}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      const blob = response.data;
      const fileUrl = URL.createObjectURL(blob);
      setCommissionPdfUrl(fileUrl);
      setShowCommissionModal(true);
    } catch (err) {
      console.error('Ошибка при загрузке PDF-файла:', err);
      setError('Ошибка при загрузке PDF-файла.');
      setShowErrorModal(true);
    } finally {
      // Даже если была ошибка, мы завершаем "загрузку" PDF
      setIsCommissionPdfLoading(false);
    }
  };

  const stopAcceptingOffers = async () => {
    try {
      await axios.get(`${BASE_URL}/cabinet/creditor/request/stop/${id}`, {
        withCredentials: true,
      });
      console.log('Прием предложений остановлен.');
      await fetchRequestDetails(); // Re-fetch request details to update the UI
    } catch (err) {
      console.error('Error stopping offers:', err);
      setError('Ошибка при остановке приема предложений.');
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    fetchRequestDetails();
  }, [fetchRequestDetails]);

  useEffect(() => {
    if (requestData?.product === 'TENDER' && isEditing) {
      axios
        .get(`${BASE_URL}/ui/contract/list/egz`, {
          withCredentials: true,
        })
        .then((response) => setContracts(response.data))
        .catch((error) => {
          console.error('Error fetching contracts:', error);
          setError('Error fetching contracts');
        });
    }
  }, [requestData?.product, isEditing]);

  useEffect(() => {
    if (isEditing) {
      axios
        .get(`${BASE_URL}/ui/accounts`, { withCredentials: true })
        .then((response) => setAccounts(response.data))
        .catch((error) => {
          console.error('Error fetching accounts:', error);
          setError('Error fetching accounts');
        });
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      axios
        .get(`${BASE_URL}/dictionary/get?name=industry`, { withCredentials: true })
        .then((response) => setIndustry(response.data))
        .catch((error) => {
          console.error('Error fetching industry:', error);
          setError('Error fetching industry');
        });
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && requestData?.product === 'LOAN') {
      axios
        .get(`${BASE_URL}/dictionary/get?name=funding_goal`, { withCredentials: true })
        .then((response) => setGoal(response.data))
        .catch((error) => {
          console.error('Error fetching goals:', error);
          setError('Error fetching goals');
        });
    }
  }, [requestData?.product, isEditing]);

  const isDraft = requestData?.status === 'DRAFT';

  const isFormValid = useMemo(() => {
    if (!editedData) return false;
    const {
      requestedAmount,
      requestedMinAmount,
      requestPeriod,
      requestRate,
      accountInfo,
      loanPurposeProject,
      loanPurposeProjectDesc,
    } = editedData;
    return (
      requestedAmount > 0 &&
      Number(requestedMinAmount) > 0 &&
      Number(requestPeriod) > 0 &&
      Number(requestRate) > 0 &&
      accountInfo?.iban &&
      loanPurposeProject && // Ensure project name is filled
      loanPurposeProjectDesc && // Ensure project description is filled
      !amountError &&
      !minAmountError
    );
  }, [editedData, amountError, minAmountError]);

  const calculateTimeLeft = useCallback(() => {
    if (!requestData?.requestDtEnd) {
      return '00:00:00';
    }

    const endTime = new Date(requestData.requestDtEnd).getTime();
    const now = new Date().getTime();
    const difference = endTime - now;

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((difference / (1000 * 60)) % 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60)
        .toString()
        .padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

    return '00:00:00';
  }, [requestData]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const getWhiteLabelTags = useMemo(() => {
    if (!requestData?.whiteLabels || requestData.whiteLabels.length === 0) {
      return <TagsWhiteLabel text='All' />;
    }

    return requestData.whiteLabels.map((whiteLabel, index) => {
      switch (whiteLabel.code) {
        case 'OMARKET':
          return <TagsWhiteLabel key={index} text={whiteLabel.name} className='smartcash' />;
        case 'ALL':
          return <TagsWhiteLabel key={index} text={whiteLabel.name} className='all' />;
        case 'MALAFEYEV_GROUP':
          return <TagsWhiteLabel key={index} text={whiteLabel.name} className='malafeyev' />;
        case 'BARS':
          return <TagsWhiteLabel key={index} text={whiteLabel.name} className='bars' />;
        default:
          return <TagsWhiteLabel key={index} text={whiteLabel.name} />;
      }
    });
  }, [requestData]);

  const handleShowOffers = useCallback(() => {
    setShowOffers(true);
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
    setResetFiles(false);
  };

  const handleCancelEdit = () => {
    if (requestData) {
      setIsEditing(false);
      setEditedData({ ...requestData, files: originalFiles });
      setSelectedAccount(requestData?.accountInfo || null);
      setSelectedContract(requestData?.contracts?.[0] || null);
      setMinAmountError('');
      setAmountError('');
      setResetFiles(true);
      setSelectedIndustry(requestData?.industry || null);
      setSelectedGoal(requestData.funding_goals || null);
    }
  };

  const handleSave = async () => {
    if (!editedData || !selectedAccount || minAmountError || amountError) return;
    setIsSaving(true);
    const dataToSave = {
      id: editedData.id,
      account: String(selectedAccount.id),
      product: requestData?.product,
      requestedAmount: String(editedData.requestedAmount),
      requestPeriod: String(editedData.requestPeriod),
      requestRate: String(editedData.requestRate),
      requestedMinAmount: String(editedData.requestedMinAmount),
      loanPurposeProject: String(editedData.loanPurposeProject),
      loanPurposeProjectDesc: String(editedData.loanPurposeProjectDesc),
      logoFileId: String(editedData.logoFileId),
      docLink: String(requestData?.docLink),
      files: editedData.files.map((file) => ({
        file: file.file,
        code: file.code,
        title: file.title || '',
      })),
      contracts: selectedContract?.id
        ? [
            {
              id: selectedContract.id,
              num: selectedContract.contractNumberSys || selectedContract.num,
              type: 'egz',
            },
          ]
        : [],
      industry: {
        id: selectedIndustry.id,
      },
      funding_goals: selectedGoal?.id
        ? {
            id: selectedGoal.id,
          }
        : null,
    };

    try {
      await axios.post(`${BASE_URL}/cabinet/creditor/request/edit`, dataToSave, {
        withCredentials: true,
      });
      setRequestData({
        ...editedData,
        contracts: [
          { id: selectedContract?.id, num: selectedContract?.contractNumberSys, type: 'egz' },
        ],
      });
      setOriginalFiles(editedData.files);
      setIsEditing(false);
      setResetFiles(false);
      fetchRequestDetails();
    } catch (err) {
      setError('Ошибка сохранения данных: ' + (err instanceof Error ? err.message : String(err)));
      setShowErrorModal(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handlePublish = async () => {
    // 1. Проверка на наличие хотя бы одного файла со статусом SUCCESSFULLY
    const hasSuccessfullyFile = editedData?.files.some(
      (file) => file.status?.code === 'SUCCESSFULLY',
    );

    if (!hasSuccessfullyFile) {
      // Если нет — показываем предупреждение и выходим
      setShowWarningModal(true);
      return;
    }

    // 2. Если у заявки вообще нет комиссий, то договор комиссии не нужен — публикуем сразу
    if (requestData?.platformCommission == null && requestData?.whiteLabelCommission == null) {
      await executePublish();
      return;
    }

    // 3. Если договор комиссии нужен, но не подписан...
    if (requestData && !requestData.isCommissionContractSigned) {
      // Если договор ещё не сформирован (commissionContractDoc == null) —
      // решите, что именно делать:
      // - пропустить проверку,
      // - или показать ошибку, что договор не сформирован.
      if (!requestData.commissionContractDoc) {
        setError('Комиссионный договор не сформирован. Обратитесь к администратору.');
        setShowErrorModal(true);
        return;
      }

      // Если файл договора комиссии есть, но не подписан — показываем PDF
      // для подписания и выходим
      await handleShowCommissionDoc();
      return;
    }

    // 4. Если договор комиссии (или его отсутствие) не блокирует нас — публикуем
    await executePublish();
  };

  const executePublish = async () => {
    setIsPublishing(true);
    try {
      await axios.get(`${BASE_URL}/cabinet/creditor/request/run/${id}`, {
        withCredentials: true,
      });
      await fetchRequestDetails();
    } catch (err) {
      setError('Ошибка публикации');
      setShowErrorModal(true);
    } finally {
      setIsPublishing(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/cabinet/creditor/request/delete/${id}`, {
        withCredentials: true,
      });
      navigate('/applications');
    } catch (err) {
      setError('Ошибка при удалении: ' + (err instanceof Error ? err.message : String(err)));
      setShowErrorModal(true);
    }
  };

  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
  };

  const toggleContractDropdown = () => {
    setIsContractDropdownOpen(!isContractDropdownOpen);
  };

  const toggleIndustryDropdown = () => {
    setIsIndustryDropdownOpen(!isIndustryDropdownOpen);
  };

  const toggleGoalDropdown = () => {
    setIsGoalDropdownOpen(!isGoalDropdownOpen);
  };

  const handleAccountSelect = (account: Account) => {
    setSelectedAccount(account);
    setIsAccountDropdownOpen(false);
  };

  const handleIndustrySelect = (industry: any) => {
    const formattedIndustry = {
      id: industry.id,
      code: industry.code,
      name: {
        text_ru: industry.name,
        text_kk: industry.name,
        text_en: industry.name,
      },
    };
    setSelectedIndustry(formattedIndustry);
    setIsIndustryDropdownOpen(false);
  };

  const handleGoalSelect = (goal: any) => {
    const formattedGoals = {
      id: goal.id,
      code: goal.code,
      name: {
        text_ru: goal.name,
        text_kk: goal.name,
        text_en: goal.name,
      },
    };
    setSelectedGoal(formattedGoals);
    setIsGoalDropdownOpen(false);
  };

  const handleContractSelect = (contract: any) => {
    setSelectedContract(contract);
    setIsContractDropdownOpen(false);
    validateAmounts(editedData?.requestedAmount, editedData?.requestedMinAmount, contract);
  };

  const handleMinAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinAmount = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        requestedMinAmount: newMinAmount,
      },
    );
    validateAmounts(editedData?.requestedAmount, newMinAmount, selectedContract);
  };

  const handleProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        loanPurposeProject: projectName,
      },
    );
  };

  const handleProjectDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const projectDescription = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        loanPurposeProjectDesc: projectDescription,
      },
    );
  };

  const handleRequestedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRequestedAmount = e.target.value;
    setEditedData(
      editedData && {
        ...editedData,
        requestedAmount: newRequestedAmount,
      },
    );
    validateAmounts(newRequestedAmount, editedData?.requestedMinAmount, selectedContract);
  };

  const validateAmounts = (requestedAmount?: any, requestedMinAmount?: any, contract?: any) => {
    let newMinAmountError = '';
    let newAmountError = '';

    if (
      requestedAmount &&
      requestedMinAmount &&
      Number(requestedMinAmount) > Number(requestedAmount)
    ) {
      newMinAmountError = 'Минимальный порог входа не может превышать сумму займа';
    }

    if (contract) {
      const contractAmount = contract.sumNoNds || contract.contractSum || 0;
      if (requestedAmount && requestedAmount > contractAmount) {
        newAmountError = 'Сумма займа не может превышать сумму контракта';
      }
    }

    setMinAmountError(newMinAmountError);
    setAmountError(newAmountError);
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(value) || value === '') {
      setEditedData(
        editedData && {
          ...editedData,
          requestRate: value,
        },
      );
    }
  };

  const handleFilesChange = (files: any) => {
    setEditedData((editedData) => {
      if (!editedData) return null;
      return {
        ...editedData,
        files,
      };
    });
  };

  const handleDeleteFile = (fileId: string) => {
    if (editedData) {
      const updatedFiles = editedData.files.filter((file) => file.file !== fileId);
      setEditedData({ ...editedData, files: updatedFiles });
    }
  };

  const calculatePercentage = (signedSum: any, remainingSum: any) => {
    if (!signedSum || !remainingSum || remainingSum === 0) return 0;
    return (signedSum / remainingSum) * 100;
  };

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    navigate(`/req/${id}/${newTab}`);
  };

  const brokerCommission = Number(requestData?.platformCommission || 0);
  const platformCommission = Number(requestData?.whiteLabelCommission || 0);
  const requestedAmount = Number(editedData?.requestedAmount || 0);

  const totalCommission = (requestedAmount * (brokerCommission + platformCommission)) / 100;
  const finalAmount = requestedAmount + totalCommission;

  if (isLoading) {
    return <LoadingGif />;
  }

  // === Подписание договора комиссии ===
  const handleSignOfCommission = async () => {
    if (!commissionPdfUrl || !requestData) return;

    // Ставим флаг процесса подписания
    setIsCommissionSigning(true);
    try {
      const endpoint = `${BASE_URL}/cabinet/creditor/request/commission_contract/sign`;
      const response = await commissionSign(
        commissionPdfUrl,
        endpoint,
        String(requestData?.id),
        requestData?.commissionContractDoc,
      );
      if (response.status === 200) {
        // Если подпись прошла, сразу запускаем публикацию (executePublish)
        // чтобы не заставлять пользователя ещё раз нажимать кнопку
        executePublish();
        setShowCommissionModal(false);
      }
    } catch (error) {
      console.error('Ошибка при подписании:', error);
    } finally {
      setIsCommissionSigning(false);
    }
  };

  const toggleFeedbackSection = (index: number) => {
    setExpandedFeedbackIndices((prev) => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };

  const renderPartialStars = (rating: number) => {
    const stars: JSX.Element[] = [];
    for (let i = 1; i <= 5; i++) {
      const isFull = rating >= i;
      const isHalf = !isFull && rating >= i - 0.5;

      if (isFull) {
        // Полная звезда
        stars.push(
          <StarWrapper key={i} active={true}>
            {/* Та же самая "закрашенная" звезда, что и у вас в fill */}
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M11.5474 4.96431C11.7273 4.58091 12.2727 4.5809 12.4526 4.96431L14.3588 9.02535C14.4296 9.17629 14.571 9.28199 14.7359 9.30717L19.0381 9.96449C19.4404 10.026 19.6047 10.5166 19.3205 10.8079L16.1762 14.0305C16.0654 14.1441 16.0151 14.3036 16.0406 14.4602L16.7785 18.9838C16.8457 19.3957 16.4084 19.7038 16.0431 19.5019L12.2419 17.4006C12.0914 17.3174 11.9086 17.3174 11.7581 17.4006L7.95687 19.5019C7.59158 19.7038 7.1543 19.3957 7.22149 18.9838L7.95938 14.4602C7.98492 14.3036 7.9346 14.1441 7.82378 14.0305L4.67954 10.8079C4.39533 10.5166 4.5596 10.026 4.9619 9.96449L9.26412 9.30717C9.42895 9.28199 9.57038 9.1763 9.64123 9.02535L11.5474 4.96431Z'
                fill='#0F62FE'
              />
            </svg>
          </StarWrapper>,
        );
      } else if (isHalf) {
        // Половинка
        stars.push(
          <StarWrapper key={i} active={true}>
            {/* SVG половинной звезды, которую вы прислали */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'>
              <g filter='url(#filter0_i_5305_26261)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.5476 4.96431C11.7276 4.58091 12.2729 4.58091 12.4528 4.96431L14.359 9.02536C14.4298 9.1763 14.5713 9.28199 14.7361 9.30717L19.0383 9.96449C19.4406 10.026 19.6049 10.5166 19.3207 10.8079L16.1765 14.0305C16.0656 14.1441 16.0153 14.3036 16.0409 14.4602L16.7787 18.9838C16.8459 19.3957 16.4086 19.7038 16.0434 19.5019L12.2421 17.4006C12.0916 17.3174 11.9089 17.3174 11.7583 17.4006L7.95709 19.5019C7.59181 19.7038 7.15453 19.3957 7.22172 18.9838L7.9596 14.4602C7.98515 14.3036 7.93483 14.1441 7.824 14.0305L4.67977 10.8079C4.39556 10.5166 4.55983 10.026 4.96213 9.96449L9.26435 9.30717C9.42918 9.28199 9.57061 9.1763 9.64145 9.02536L11.5476 4.96431ZM12.0002 6.35351L13.4538 9.45025C13.6663 9.90308 14.0906 10.2201 14.5851 10.2957L17.9254 10.8061L15.4607 13.3322C15.1282 13.6729 14.9773 14.1513 15.0539 14.6212L15.626 18.1285L12.7259 16.5254L12.0002 16.5L12.0002 6.35351Z'
                  fill='#0F62FE'
                />
              </g>
              <defs>
                <filter
                  id='filter0_i_5305_26261'
                  x='4.53711'
                  y='4.67676'
                  width='14.9258'
                  height='14.8887'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'>
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dx='4' />
                  <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0.0588235 0 0 0 0 0.384314 0 0 0 0 0.996078 0 0 0 1 0'
                  />
                  <feBlend mode='normal' in2='shape' result='effect1_innerShadow_5305_26261' />
                </filter>
              </defs>
            </svg>
          </StarWrapper>,
        );
      } else {
        // Пустая звезда (stroke)
        stars.push(
          <StarWrapper key={i} active={false}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
              <path
                d='M12 5.17676L13.9062 9.2378C14.0478 9.53968 14.3307 9.75107 14.6604 9.80143L18.9626 10.4588L15.8183 13.6814C15.5967 13.9085 15.496 14.2274 15.5471 14.5407L16.285 19.0643L12.4838 16.963C12.1827 16.7966 11.8173 16.7966 11.5162 16.963L7.71497 19.0643L8.45285 14.5407C8.50395 14.2274 8.40331 13.9085 8.18165 13.6814L5.03742 10.4588L9.33964 9.80143C9.6693 9.75107 9.95215 9.53969 10.0938 9.2378L12 5.17676Z'
                stroke='#0F62FE'
              />
            </svg>
          </StarWrapper>,
        );
      }
    }
    return <StarsRow>{stars}</StarsRow>;
  };
  // ▲▲▲ Добавленный код ▲▲▲

  return (
    <>
      {/* Модальное окно с договором комиссии */}
      {showCommissionModal && commissionPdfUrl && (
        <ModalOverlay>
          <ModalContent>
            <HeaderPdf>
              <img src={HeaderPdfLogo} alt='LogoPdf' />
              <div>
                <h3>Договор Комиссии</h3>
                <p>Для публикации Вам необходимо подписать договор комиссии с платформой</p>
              </div>
            </HeaderPdf>

            <div style={{ overflow: 'auto' }}>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                <Viewer
                  fileUrl={commissionPdfUrl}
                  // Как только документ загрузится, отключаем лоадер
                  onDocumentLoad={() => setIsCommissionPdfLoading(false)}
                />
              </Worker>
            </div>

            <ButtonBoxSigned>
              <NewButton
                onClick={() => setShowCommissionModal(false)}
                text='Отмена'
                className='white'
              />
              <NewButton
                text='Подписать и опубликовать заявку'
                onClick={handleSignOfCommission}
                // Блокируем кнопку, пока PDF не загрузился и/или идет процесс подписания
                disabled={isCommissionPdfLoading || isCommissionSigning}
              />
            </ButtonBoxSigned>
          </ModalContent>
        </ModalOverlay>
      )}

      {showWarningModal && (
        <WarningModal
          headerWarning='Внимание'
          textWarning='Предоставленная выписка имеет невалидный формат, в связи с чем аналитика данных выписки со стороны инвесторов невозможна.'
          buttonClose='Продолжить'
          onClick={async () => {
            try {
              setShowWarningModal(false);

              // Если isCommissionContractSigned равно null, сразу публикуем
              if (requestData?.isCommissionContractSigned === null) {
                await executePublish();
                return;
              }

              // Если договор не подписан, показываем PDF
              if (requestData && !requestData.isCommissionContractSigned) {
                await handleShowCommissionDoc();
                return;
              }

              // Если договор уже подписан, продолжаем публикацию
              await executePublish();
            } catch (error) {
              console.error('Ошибка при обработке WarningModal:', error);
              setError('Произошла ошибка. Попробуйте ещё раз.');
              setShowErrorModal(true);
            }
          }}
          onClose={() => setShowWarningModal(false)}
        />
      )}

      {showOffers ? (
        <OffersBusinessList
          requestNumber={requestData?.num}
          requestId={id}
          requestedAmount={requestData?.requestedAmount}
          requestPeriod={requestData?.requestPeriod}
          requestRate={requestData?.requestRate}
        />
      ) : (
        <Container>
          <BreadCrumbsBox>
            <p>
              <a href='/applications'>Сделки</a>
            </p>
            <span>{'>'}</span>
            <p>Сделка {requestData?.num}</p>
          </BreadCrumbsBox>
          <HeaderBox>
            <div className='leftContent'>
              <h2>Сделка {requestData?.num}</h2>
              {getWhiteLabelTags}
              <TagRisk status={requestData?.status}>
                <img src={CircleWhite} alt='circle' />
                {requestData?.statusName}
              </TagRisk>
              {!isDraft && (
                <TagTime>
                  <img src={IconRedTimer} alt='timer' />
                  <Timer>{remainingTime}</Timer>
                </TagTime>
              )}
            </div>
            {!isDraft && <NewButton onClick={handleShowOffers} text='К предложениям' />}
          </HeaderBox>
          <SwitcherBox>
            <Tab active={activeTab === 'mainInfo'} onClick={() => handleTabChange('mainInfo')}>
              Основная информация
            </Tab>
            <Tab active={activeTab === 'documents'} onClick={() => handleTabChange('documents')}>
              Документы
            </Tab>
            {isActiveRequest && (
              <Tab active={activeTab === 'feedback'} onClick={() => handleTabChange('feedback')}>
                Обратная связь
              </Tab>
            )}
          </SwitcherBox>
          <ContainerContent>
            <LeftBlock>
              {activeTab === 'mainInfo' && (
                <ContainerMain>
                  <ExpandableSection>
                    <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
                      <img src={IconHelpHouse} alt='icon' />
                      Общая информация
                      <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
                    </MainInfoTitle>
                    {isMainInfoExpanded && (
                      <MainInfoBox>
                        {isEditing ? (
                          <MainInfoContentEdit>
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Продукт</MainInfoItemText>{' '}
                                {editedData?.productName}
                              </MainInfoItem>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Минимальный порог входа</MainInfoItemText>
                                <InputNew
                                  value={editedData?.requestedMinAmount?.toString() || ''}
                                  onChange={handleMinAmountChange}
                                  error={minAmountError}
                                  type='number'
                                />
                              </MainInfoItem>
                            </EditInputBox>
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Длительность финансирования</MainInfoItemText>
                                <InputNew
                                  value={editedData?.requestPeriod?.toString() || ''}
                                  onChange={(e) =>
                                    setEditedData(
                                      editedData && {
                                        ...editedData,
                                        requestPeriod: String(e.target.value),
                                      },
                                    )
                                  }
                                  type='number'
                                />
                              </MainInfoItem>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Запрошенная сумма</MainInfoItemText>
                                <InputNew
                                  value={editedData?.requestedAmount?.toString() || ''}
                                  onChange={handleRequestedAmountChange}
                                  type='number'
                                />
                                {amountError && <ErrorTitle>{amountError}</ErrorTitle>}
                              </MainInfoItem>
                            </EditInputBox>
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Сумма с учетом комиссий</MainInfoItemText>
                                <InputNew
                                  value={finalAmount.toLocaleString()}
                                  type='text'
                                  disabled={true}
                                  readOnly={true}
                                />
                              </MainInfoItem>
                            </EditInputBox>
                          </MainInfoContentEdit>
                        ) : (
                          <MainInfoContent>
                            {requestData?.publicationDt && (
                              <MainInfoItem>
                                <MainInfoItemText>Дата публикации</MainInfoItemText>{' '}
                                {formatDateTime(requestData.publicationDt)}
                              </MainInfoItem>
                            )}
                            <MainInfoItem>
                              <MainInfoItemText>Продукт</MainInfoItemText>{' '}
                              {requestData?.productName}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Минимальный порог входа</MainInfoItemText>{' '}
                              {requestData?.requestedMinAmount?.toLocaleString() || 'N/A'} ₸
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Длительность финансирования</MainInfoItemText>{' '}
                              {requestData?.requestPeriod} дней
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Запрошенная сумма</MainInfoItemText>{' '}
                              {requestData?.originalAmount?.toLocaleString() || 'N/A'} ₸
                            </MainInfoItem>
                          </MainInfoContent>
                        )}
                      </MainInfoBox>
                    )}
                  </ExpandableSection>
                  <ExpandableSection>
                    <MainInfoTitle onClick={() => setIsMainInfoFinancing(!isMainInfoFinancing)}>
                      <img src={IconHelpHouse} alt='icon' />
                      Финансовые условия
                      <ArrowIcon
                        src={isMainInfoFinancing ? IconArrowDown : IconArrow}
                        alt='arrow'
                      />
                    </MainInfoTitle>
                    {isMainInfoFinancing && (
                      <MainInfoBox>
                        {isEditing ? (
                          <MainInfoContentEdit>
                            {requestData?.product === 'TENDER' && (
                              <>
                                {requestData?.contracts?.[0]?.customerName || 'N/A'}
                                <MainInfoItem>
                                  <DropdownContainer ref={accountDropdownRef}>
                                    <label>Выбрать контракт</label>
                                    <DropdownHeader
                                      onClick={toggleContractDropdown}
                                      isOpen={isContractDropdownOpen}
                                      $hasContent={!!selectedContract}>
                                      {selectedContract
                                        ? `${selectedContract.num || selectedContract.contractNumberSys} ( сумма договора ${formatNumberWithSpaces(
                                            selectedContract.sumNoNds?.toString() ||
                                              selectedContract.contractSum?.toString() ||
                                              '0',
                                          )} тенге )`
                                        : 'Выбрать контракт'}
                                      <ArrowIcon
                                        src={isContractDropdownOpen ? IconArrowDown : IconArrow}
                                        alt='arrow'
                                      />
                                    </DropdownHeader>

                                    {isContractDropdownOpen && (
                                      <DropdownListContainer>
                                        {contracts.map((contract) => (
                                          <DropdownListItem
                                            key={contract.id}
                                            onClick={() => handleContractSelect(contract)}>
                                            {`${contract.contractNumberSys || 'N/A'} ( сумма договора ${formatNumberWithSpaces(
                                              contract.contractSum?.toString() || '0',
                                            )} тенге )`}
                                          </DropdownListItem>
                                        ))}
                                      </DropdownListContainer>
                                    )}
                                  </DropdownContainer>
                                </MainInfoItem>
                              </>
                            )}
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <DropdownContainer ref={accountDropdownRef}>
                                  <label>Выбрать счет</label>
                                  <DropdownHeader
                                    onClick={toggleAccountDropdown}
                                    isOpen={isAccountDropdownOpen}
                                    $hasContent={!!selectedAccount}>
                                    {selectedAccount ? selectedAccount.iban : 'Выбрать счет'}
                                    <ArrowIcon
                                      src={isAccountDropdownOpen ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                    />
                                  </DropdownHeader>
                                  {isAccountDropdownOpen && (
                                    <DropdownListContainer>
                                      {accounts.map((account) => (
                                        <DropdownListItem
                                          key={account.id}
                                          onClick={() => handleAccountSelect(account)}>
                                          {account.iban}
                                        </DropdownListItem>
                                      ))}
                                    </DropdownListContainer>
                                  )}
                                </DropdownContainer>
                              </MainInfoItem>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Процентная ставка в месяц</MainInfoItemText>
                                <InputNew
                                  value={editedData?.requestRate?.toString() || ''}
                                  onChange={handleRateChange}
                                  type='text'
                                />
                              </MainInfoItem>
                            </EditInputBox>
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Комиссия брокера</MainInfoItemText>
                                <InputNew
                                  value={`${requestData?.platformCommission} % `}
                                  type='text'
                                  disabled={true}
                                  readOnly={true}
                                />
                              </MainInfoItem>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Комиссия платформы</MainInfoItemText>
                                <InputNew
                                  value={`${requestData?.whiteLabelCommission} %`}
                                  type='text'
                                  disabled={true}
                                  readOnly={true}
                                />
                              </MainInfoItem>
                            </EditInputBox>
                          </MainInfoContentEdit>
                        ) : (
                          <MainInfoContent>
                            <MainInfoItem>
                              <MainInfoItemText>№ Счета</MainInfoItemText>{' '}
                              {requestData?.accountInfo?.iban}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Процентная ставка в месяц</MainInfoItemText>{' '}
                              {requestData?.requestRate} %
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Комиссия брокера</MainInfoItemText>{' '}
                              {requestData?.whiteLabelCommission
                                ? `${requestData.whiteLabelCommission} %`
                                : ' 0 %'}
                            </MainInfoItem>
                            <MainInfoItem>
                              <MainInfoItemText>Комиссия платформы</MainInfoItemText>{' '}
                              {requestData?.platformCommission
                                ? `${requestData.platformCommission} %`
                                : ' 0 %'}
                            </MainInfoItem>

                            {requestData?.product === 'LOAN' && (
                              <>
                                <MainInfoItem>
                                  <MainInfoItemText>Вид залога</MainInfoItemText>{' '}
                                  {requestData?.collateral?.name?.text_ru
                                    ? requestData.collateral.name.text_ru
                                    : 'Нет залога'}
                                </MainInfoItem>
                              </>
                            )}
                            {requestData?.product === 'TENDER' && (
                              <>
                                <MainInfoItem>
                                  <MainInfoItemText>Сумма контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.sumNoNds?.toLocaleString() || 'N/A'}{' '}
                                  ₸
                                </MainInfoItem>
                                <MainInfoItem>
                                  <MainInfoItemText>№ Контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.num || 'N/A'}
                                </MainInfoItem>
                                <MainInfoItem>
                                  <MainInfoItemText>Название контракта</MainInfoItemText>{' '}
                                  {requestData?.contracts?.[0]?.customerName || 'N/A'}
                                </MainInfoItem>
                              </>
                            )}
                            <MainInfoItem>
                              <MainInfoItemText>Итоговая сумма</MainInfoItemText>{' '}
                              {requestData?.requestedAmount.toLocaleString()} ₸
                            </MainInfoItem>
                          </MainInfoContent>
                        )}
                      </MainInfoBox>
                    )}
                  </ExpandableSection>
                  <ExpandableSection>
                    <MainInfoTitle onClick={() => setIsMainInfoDecor(!isMainInfoDecor)}>
                      <img src={IconDecor} alt='icon' />
                      Оформление
                      <ArrowIcon src={isMainInfoDecor ? IconArrowDown : IconArrow} alt='arrow' />
                    </MainInfoTitle>
                    {isMainInfoDecor && (
                      <MainInfoBox>
                        {isEditing ? (
                          <MainInfoContentEdit>
                            <EditInputBox>
                              <MainInfoItem isEditing={isEditing}>
                                <MainInfoItemText>Название проекта</MainInfoItemText>
                                <InputNew
                                  value={editedData?.loanPurposeProject || ''}
                                  onChange={handleProjectName}
                                />
                              </MainInfoItem>
                              <MainInfoItem isEditing={isEditing}>
                                <DropdownContainer>
                                  <label>Выбрать индустрию</label>
                                  <DropdownHeader
                                    onClick={toggleIndustryDropdown}
                                    isOpen={isIndustryDropdownOpen}
                                    $hasContent={!!selectedIndustry}>
                                    {selectedIndustry
                                      ? selectedIndustry.name.text_ru
                                      : 'Выбрать индустрию'}
                                    <ArrowIcon
                                      src={isIndustryDropdownOpen ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                    />
                                  </DropdownHeader>
                                  {isIndustryDropdownOpen && (
                                    <DropdownListContainer>
                                      {industrys.map((industry) => (
                                        <DropdownListItem
                                          key={industry.id}
                                          onClick={() => handleIndustrySelect(industry)}>
                                          {typeof industry.name === 'string'
                                            ? industry.name
                                            : industry.name.text_ru}
                                        </DropdownListItem>
                                      ))}
                                    </DropdownListContainer>
                                  )}
                                </DropdownContainer>
                              </MainInfoItem>
                            </EditInputBox>

                            {requestData?.product === 'LOAN' && (
                              <MainInfoItem isEditing={isEditing}>
                                <DropdownContainer>
                                  <label>Выбрать цель займа</label>
                                  <DropdownHeader
                                    onClick={toggleGoalDropdown}
                                    isOpen={isGoalDropdownOpen}
                                    $hasContent={!!selectedGoal}>
                                    {selectedGoal ? selectedGoal.name.text_ru : 'Выбрать цель'}
                                    <ArrowIcon
                                      src={isGoalDropdownOpen ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                    />
                                  </DropdownHeader>
                                  {isGoalDropdownOpen && (
                                    <DropdownListContainer>
                                      {goals.map((goal) => (
                                        <DropdownListItem
                                          key={goal.id}
                                          onClick={() => handleGoalSelect(goal)}>
                                          {typeof goal.name === 'string'
                                            ? goal.name
                                            : goal.name.text_ru}
                                        </DropdownListItem>
                                      ))}
                                    </DropdownListContainer>
                                  )}
                                </DropdownContainer>
                              </MainInfoItem>
                            )}
                            <MainInfoItem isEditing={isEditing}>
                              <MainInfoItemText>Описание проекта</MainInfoItemText>
                              <TextArea
                                placeholder='Кратко расскажите историю своего бизнеса и опишите детально проект. Качественно описанные проекты привлекают на 30% больше инвестиций.'
                                value={editedData?.loanPurposeProjectDesc || ''}
                                onChange={handleProjectDescription}
                                className='maxApplication'
                              />
                            </MainInfoItem>
                          </MainInfoContentEdit>
                        ) : (
                          <MainDecorContent>
                            <div>
                              {logoSrc && <MainDecorLogo src={logoSrc} alt='logo' />}
                              <DesignBox>
                                <MainDecorTitle>{requestData?.loanPurposeProject}</MainDecorTitle>
                                <InfoDescription>
                                  {requestData?.loanPurposeProjectDesc}
                                </InfoDescription>
                              </DesignBox>
                            </div>
                            <MainDecorInfoBox>
                              <MainInfoContent>
                                {requestData?.funding_goals?.name?.text_ru ? (
                                  <MainInfoItem>
                                    <MainInfoItemText>Цель займа:</MainInfoItemText>
                                    {requestData.funding_goals.name.text_ru}
                                  </MainInfoItem>
                                ) : (
                                  <MainInfoItem>
                                    <MainInfoItemText>Цель займа:</MainInfoItemText>
                                    Исполнение тендера
                                  </MainInfoItem>
                                )}

                                <MainInfoItem>
                                  <MainInfoItemText>Индустрия:</MainInfoItemText>
                                  {requestData?.industry?.name?.text_ru}
                                </MainInfoItem>
                              </MainInfoContent>
                            </MainDecorInfoBox>
                          </MainDecorContent>
                        )}
                      </MainInfoBox>
                    )}
                  </ExpandableSection>
                </ContainerMain>
              )}
              {activeTab === 'documents' && (
                <ApplicationDocumentsList
                  files={editedData?.files ?? []}
                  isEditing={isEditing}
                  onFilesChange={handleFilesChange}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  resetFiles={resetFiles}
                  onDeleteFile={handleDeleteFile}
                  docLink={requestData?.docLink}
                  requestData={requestData} // Pass requestData here
                  selectedAccount={selectedAccount}
                  selectedContract={selectedContract}
                  selectedIndustry={selectedIndustry}
                  selectedGoal={selectedGoal}
                />
              )}
              {activeTab === 'feedback' && isActiveRequest && (
                <div style={{ width: '100%' }}>
                  {isFeedbackLoading ? (
                    <LoadingGif />
                  ) : feedbackList.length === 0 ? (
                    <div>
                      <ExpandableSection>
                        <MainInfoTitle>
                          <img src={StarIconMini} alt='icon' />
                          Обратная связь отсутствует
                        </MainInfoTitle>
                      </ExpandableSection>
                    </div>
                  ) : (
                    feedbackList.map((feedback, index) => {
                      const average = feedback.avg || 0;

                      return (
                        <ExpandableSection key={index}>
                          <MainInfoTitle onClick={() => toggleFeedbackSection(index)}>
                            <img src={StarIconMini} alt='icon' />
                            Обратная связь от{' '}
                            {new Date(feedback.dtCreate).toLocaleDateString('ru-RU')}
                            {/* Показываем усреднённую оценку справа */}
                            <ArrowIcon
                              src={expandedFeedbackIndices[index] ? IconArrowDown : IconArrow}
                              alt='arrow'
                            />
                          </MainInfoTitle>

                          {expandedFeedbackIndices[index] && (
                            <MainInfoBox>
                              <FeedbackBox>
                                <FeedbackBoxHeader>
                                  <p>Общая оценка проекту</p>

                                  <FeedbackBoxStar>
                                    <img src={StarIconMini} alt='star' />
                                    {average.toFixed(2)}
                                  </FeedbackBoxStar>
                                </FeedbackBoxHeader>

                                {/* Блоки с рейтингами */}
                                <RatingsRow>
                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Финансовая устойчивость
                                      <p>Насколько стабилен финансовый статус заемщика.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.finStabilityRank)}
                                  </RatingItemInvest>

                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Условия сделки
                                      <p>Выгодность и прозрачность предложенных условий.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.conditionRank)}
                                  </RatingItemInvest>
                                </RatingsRow>
                                <RatingsRow>
                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Репутация заемщика
                                      <p>Надежность и история заемщика на платформе.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.reputationRank)}
                                  </RatingItemInvest>
                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Компетенция заемщика
                                      <p>Опыт и квалификация заемщика в бизнесе.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.competentionRank)}
                                  </RatingItemInvest>
                                </RatingsRow>
                                <RatingsRow>
                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Реалистичность целей
                                      <p>Насколько заявленные цели выглядят выполнимыми.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.realisticRank)}
                                  </RatingItemInvest>
                                  <RatingItemInvest>
                                    <RatingLabelInvest>
                                      Достаточность ресурсов
                                      <p>Обеспеченность заемщика необходимыми ресурсами.</p>
                                    </RatingLabelInvest>
                                    {renderPartialStars(feedback.resourcesRank)}
                                  </RatingItemInvest>
                                </RatingsRow>

                                {/* Поле комментария (feedback) */}
                                <FeedbackDescription>
                                  <h4>Комментарии</h4>
                                  <p>{feedback.feedback || 'Нет комментариев'}</p>
                                </FeedbackDescription>
                                {/* Поле suggestions */}
                                <FeedbackDescription>
                                  <h4>Что я могу быть полезен?</h4>
                                  <p>{feedback.suggestions || 'Нет предложений'}</p>
                                </FeedbackDescription>
                              </FeedbackBox>
                            </MainInfoBox>
                          )}
                        </ExpandableSection>
                      );
                    })
                  )}
                </div>
              )}
            </LeftBlock>
            <RightBlock>
              {isDraft ? (
                <DraftBox>
                  <h4>Сделка находится в статусе черновик</h4>
                  <p>Опубликуйте чтобы инвесторы могли сделать предложение</p>
                </DraftBox>
              ) : (
                <InfoBoxRight>
                  <InfoBoxRightCard>
                    <div>
                      <p>Полученно предложений:</p>
                      <span>{requestData?.offerCount} </span>
                    </div>
                    <div>
                      <p>Принято предложений на сумму:</p>
                      <span>{requestData?.approvedAmount?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                  </InfoBoxRightCard>
                  <InfoBoxRightCard>
                    <div>
                      <p>Осталось собрать по сделке:</p>
                      <span>{requestData?.remainingSum?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                    <div>
                      <p>Подписано договоров на сумму:</p>
                      <span>{requestData?.signedContractSum?.toLocaleString() || 'N/A'} ₸</span>
                    </div>
                  </InfoBoxRightCard>
                  <ProgressBarContainer>
                    <ProgressBarFiller
                      style={{
                        width: `${calculatePercentage(
                          requestData?.signedContractSum,
                          requestData?.remainingSum,
                        )}%`,
                      }}
                    />
                  </ProgressBarContainer>
                </InfoBoxRight>
              )}
              {!isDraft ? (
                <ButtonBox>
                  {requestData?.acceptNewOffers ? (
                    <NewButton text='Остановить прием предложений' onClick={stopAcceptingOffers} />
                  ) : (
                    <AcceptNewOffersNo>Прием предложений остановлен</AcceptNewOffersNo>
                  )}
                </ButtonBox>
              ) : (
                <DraftButtonBox>
                  {isEditing ? (
                    <>
                      <NewButton
                        onClick={handleSave}
                        text='Сохранить'
                        disabled={
                          !isFormValid ||
                          !!minAmountError ||
                          !!amountError ||
                          isUploading ||
                          isSaving
                        }
                        loading={isSaving}
                      />
                      <NewButton onClick={handleCancelEdit} text='Отменить' className='red' />
                    </>
                  ) : (
                    <>
                      <NewButton onClick={handleEdit} text='Редактировать' className='white' />
                      <NewButton
                        onClick={handlePublish}
                        text='Опубликовать'
                        // Добавили блокировку «Опубликовать», если идёт загрузка/подписание договора
                        disabled={isPublishing || isCommissionPdfLoading || isCommissionSigning}
                      />
                      <NewButton onClick={handleDelete} text='Удалить' className='red' />
                    </>
                  )}
                </DraftButtonBox>
              )}
              {!isDraft && (
                <SentOffersList ids={id} num={requestData?.num} setShowOffers={setShowOffers} />
              )}
            </RightBlock>
          </ContainerContent>
          {showErrorModal && (
            <ErrorModal
              headerError='Ошибка'
              textError={error}
              buttonClose='Закрыть'
              onClick={() => setShowErrorModal(false)}
            />
          )}
        </Container>
      )}
    </>
  );
};
